<template>
  <div class="qa container">
    <div class="panel">
      <div class="tit">
        <img src="@/assets/image/tit-qa.png" width="100%">
        <span>常见问题</span>
      </div>
      <div class="content">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      content: ''
    }
  },
  mounted () {
    const that = this
    that.$http.get('/v1/articles/' + process.env.VUE_APP_QA_ID).then(res => {
      that.content = res.data.content
    })
  }
}
</script>
<style lang="scss">
.qa {
  .content {
    .section:first-of-type {
      margin-top: 10px;
    }

    .section {
      margin-top: 30px;

      .title {
        font-size: 14px;
        color: #111111;
        letter-spacing: 0;
        text-align: left;
        font-weight: bold;
        padding-bottom: 4px;
      }

      .item {
        margin-top: 20px;

        .q {
          font-size: 13px;
          color: #496EA2;
          letter-spacing: 0;
          text-align: left;
          line-height: 20px;
        }

        .a {
          font-size: 13px;
          color: #000000;
          letter-spacing: 0;
          text-align: left;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
